import React, { useEffect } from "react"
import SEO from "../../components/Seo/Seo"

import { navigate } from "gatsby"

const careersMetadata = {
  Title: "Vacatures - Flipbase",
  Keywords: ["Flipbase vacatures", "Werken bij Flipbase vacancies"],
  Description:
    "Werken bij Flipbase? Bekijk onze actuele vacatures en solliciteer nu!",
  Image: "/seo-image-general.png",
}

export default () => {
  useEffect(() => {
    navigate("/Careers")
  }, [])

  return (
    <SEO
      title={careersMetadata.Title}
      description={careersMetadata.Description}
      image={careersMetadata.Image}
      keywords={careersMetadata.Keywords}
    />
  )
}
